import { useEffect, useRef, useState } from "react";
import MultiSelectDropdown from "../../../components/MultiSelect";
import "./coachreschedulebooking.css";
import moment, { duration } from "moment";
import { toast } from "react-toastify";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../../widgets/firebase";
import { hours } from "../../../utils/utilityFunctions";
import SingleSelectDropdown from "../../../components/SingleSelect";

const CoachRescheduleBooking = ({
  selectedBooking,
  open,
  close,
  refetchEvents,
}) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillsData, setSkillsData] = useState([]);
  const [isCancelledClicked, setIsCancelledClicked] = useState(false);
  const cancelNoteRef = useRef(null);
  const token = localStorage.getItem("accessToken");
  const [bookingDetails, setBookingDetails] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    skills: "",
    duration: "",
    notes: "",
  });
  const updateSkills = (e) => {
    const {
      target: { value },
    } = e;
    setSelectedSkills(typeof value === "string" ? value.split(",") : value);
  };

  const ref = collection(db, "userrequest");

  const dateStr = selectedBooking.start;
  const formattedDate = moment(dateStr).format("YYYY-MM-DD");

  const fetchSkills = async () => {
    const apiUrl = `${window.env_url}/v1/filterskills`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setSkillsData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  const skills = skillsData.map((data, index) => {
    return {
      name: data.title,
      code: data.id,
    };
  });

  const getDateString = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObj.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  

  const getDuration = (startTime, endTime) => {
    const startDate = new Date(startTime);
    const endDate = new Date(endTime);
    const diffInMilliseconds = endDate - startDate;
    const diffInHours = diffInMilliseconds / (1000 * 60 * 60);
    console.log("diffInHours", diffInHours);
    return diffInHours;
  };

  const getFileUrl = `${window.env_url}/v1/files/`;
  const profileImage = sessionStorage.getItem("profPicture");
  let imgPath;
  if (profileImage?.includes("avataaars.io")) {
    imgPath = profileImage;
  } else {
    imgPath = getFileUrl + profileImage;
  }

  console.log("selectedBooking", selectedBooking);
  const updateBookingDetails = () => {
    setSelectedSkills(selectedBooking?.skills);
    const _bookingDetails = {
      notes: selectedBooking?.notes,
      start: getDateString(selectedBooking?.start),
      duration: getDuration(selectedBooking?.start, selectedBooking?.end),
      startTime: getTime(selectedBooking?.start),
    };
    setBookingDetails(_bookingDetails);
  };

  const getTime = (dateString) => {
    const dateObj = new Date(dateString);
    const hours = dateObj.getHours();
    const minutes = dateObj.getMinutes().toString().padStart(2, "0");
    const time = `${hours}:${minutes}`;
    return time;
  };

  const updateBooking = async (updatedBooking, type) => {
    console.log("UpdateBookingEntered");
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(
        `${window.env_url}/v1/coachbookings/${selectedBooking?.id}`,
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(updatedBooking),
        }
      );
      const data = await response.json();

      let dataToSend = {
        username: sessionStorage.getItem("fullName"),
        message: `${type} a meeting room`,
        duration: data.body[0].duration,
        profileImage: imgPath,
        createdAt: Date.now(),
      };
      await addDoc(ref, dataToSend);
      refetchEvents();
    } catch (error) {}
  };

  const formRequestBody = () => {
    const startTime = moment(bookingDetails?.startTime, "HH:mm:ss");
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = startTimeMoment
      .clone()
      .add(bookingDetails?.duration, "hours");

    let startDate = moment(bookingDetails?.start);
    let endDate = moment(bookingDetails?.end);

    if (
      !startTimeMoment?._i ||
      !endTimeMoment._i ||
      !selectedSkills?.length ||
      !bookingDetails?.duration ||
      bookingDetails?.notes === ""
    ) {
      toast.error("Please fill all the fields");
      return;
    }

    const roomEvent = {
      prevBookingDate: formattedDate,
      checkInDate: moment(startDate).format("YYYY-MM-DD"),
      checkOutDate: moment(startDate).format("YYYY-MM-DD"),
      checkInTime: startTimeMoment.format("HH:mm:ss"),
      checkOutTime: endTimeMoment.format("HH:mm:ss"),
      skills: selectedSkills.map((item, i) => item),
      duration: bookingDetails?.duration,
      notes: bookingDetails?.notes,
      visibility: selectedBooking?.visibility,
      isRescheduled: selectedBooking?.isRescheduled
        ? true
        : selectedBooking?.visibility === "accepted"
        ? true
        : false,
    };
    return roomEvent;
  };
  const handleUpdateEvent = () => {
    const event = formRequestBody();
    event.visibility = "pending";
    updateBooking(event, "Updated");
    close();
  };

  useEffect(() => {
    fetchSkills();
    updateBookingDetails();
  }, []);

  const bookingCancelHandler = () => {
    const event = formRequestBody();
    event.visibility = "rejected";
    event.notes = cancelNoteRef.current.value;
    updateBooking(event, "Cancelled");
    setIsCancelledClicked(false);
    close();
  };

  const CancelContent = () => {
    return (
      <div className="cancel-booking-container">
        <div>
          <div className="cancel-booking-header">Cancel Booking</div>
        </div>
        <div>
          <textarea
            style={{ resize: "none" }}
            placeholder="Comment..."
            ref={cancelNoteRef}
          ></textarea>
        </div>
        <div className="form-sbt-btn reschedule-cancel-dialog-cancel-button">
          <button onClick={bookingCancelHandler}>Submit</button>
        </div>
      </div>
    );
  };

  const cancelHandler = () => {
    setIsCancelledClicked(true);
  };
  if (isCancelledClicked) return <CancelContent />;
  return (
    <div className="bmrf coach-reschedule-booking-form-container">
      <header>
        <div>Update Your Booking</div>
      </header>
      <div className="bmrf-form coach-reschedule-form">
        <div className="formFieldContainer">
          <div className="row1">
            <div>
              <label htmlFor="" className="makebold">
                Date
              </label>
              <input
                type="date"
                name="startDate"
                value={bookingDetails?.start}
                onChange={(event) =>
                  setBookingDetails({
                    ...bookingDetails,
                    start: event.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="row2">
            <div>
              <label htmlFor="" className="makebold">
                Time
              </label>
              <input
                type="time"
                name="startTime"
                value={bookingDetails?.startTime}
                onChange={(event) => {
                  setBookingDetails({
                    ...bookingDetails,
                    startTime: event.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="row3" style={{ border: "none" }}>
            <div>
              <label htmlFor="" className="makebold">
                Duration
              </label>
              <SingleSelectDropdown
                options={hours}
                value={bookingDetails?.duration}
                onChange={(event) =>
                  setBookingDetails({
                    ...bookingDetails,
                    duration: event.target.value,
                  })
                }
                label="Duration"
              />
            </div>
          </div>

          <div className="row4">
            <div>
              <MultiSelectDropdown
                value={selectedSkills}
                onChangeEvent={updateSkills}
                options={skills}
                placeholder="Select Skills"
              />
            </div>
          </div>

          <div className="row5">
            <div className="row5" style={{ display: "block" }}>
              <label htmlFor="" className="makebold">
                Description
              </label>
              <textarea
                type="text"
                name="notes"
                value={bookingDetails?.notes}
                placeholder="Description"
                onChange={(event) =>
                  setBookingDetails({
                    ...bookingDetails,
                    notes: event.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="form-sbt-btn reschedule-form-buttons">
          <button
            onClick={handleUpdateEvent}
            className="reschedule-submit-button"
          >
            {selectedBooking?.visibility === "accepted"
              ? "Reschedule"
              : "Update"}
          </button>
          <button className="reschedule-cancel-button" onClick={cancelHandler}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoachRescheduleBooking;
