import LogoImage from "../../assets/png/Logo.png";
import "./Navbar.css";
import { ReactComponent as NavIcon } from "../../assets/svg/nav.svg";
import { ReactComponent as BellIcon } from "../../assets/svg/Notification.svg";
import { ReactComponent as Dropdown } from "../../assets/svg/Dropdown.svg";
import IncuHubLogo from "../../assets/png/IncuHubLogo.png";
import  NFIHLogo  from "../../assets/png/NFIH-logo.png"
import { ReactComponent as SearchIcon } from "../../assets/svg/SearchIcon.svg";
import Sidebar from "../sidebar/Sidebar";
import NotificationWidget from "../../widgets/NotificationWidget.jsx";
import { useEffect, useState } from "react";
import ProfileWidget from "../../dialogs/profile-ui/ProfileWidget.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileOptions from "../../dialogs/profile-options/ProfileOptions.jsx";
import { useMediaQuery } from "@mui/material";
import axios from "axios";
import { token, usertype } from "../../utils/utilityFunctions.jsx";
import { useQuery } from "react-query";
import CoachNotificationWidget from "../../widgets/CoachNotificationWidget.jsx";

const Navbar = () => {
  const [isProfileClicked, setIsProfileClicked] = useState(false);
  const location = useLocation();
  const [navBarData, setNavBarData] = useState(null);
  const [coachData, setCoachData] = useState([]);
  const { pathname } = location;
  const getFileUrl = `${window.env_url}/v1/files/`;
  const userName = sessionStorage.getItem("fullName");
  const profImage = sessionStorage.getItem("profPicture");
  const handleProfile = () => {
    setIsProfileClicked(!isProfileClicked);
  };

  const fetchUserDetails = async () => {
    try {
      const { data } = await axios.get(`${window.env_url}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const userData = data.body[0];
      const imgData = userData.image;
      if (imgData.includes("avataaars.io")) {
        userData.imageUrl = imgData;
      } else {
        const imageResponse = await axios.get(
          `${window.env_url}/v1/files/${userData.image}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "arraybuffer",
          }
        );

        const blob = new Blob([imageResponse.data], { type: "image/jpeg" });
        const url = URL.createObjectURL(blob);
        userData.imageUrl = url;
      }

      return userData;
    } catch (error) {
      console.log(error);
    }
  };

  const [userData, setUserData] = useState({});
  const [tempFlag, setTempFlag] = useState(true);
  // const { data, isLoading } = useQuery("userData", fetchUserDetails, {
  //   onSuccess: (data) => {
  //     if (data) {
  //       setUserData(data);
  //       if (tempFlag && data?.imageUrl) {
  //         setTempFlag(false);
  //         // setProfileImage(data.imageUrl);
  //       }
  //     }
  //   },
  // });

  const { data, isLoading, refetch } = useQuery("userData", fetchUserDetails, {
    onSuccess: (fetchedData) => {
      setUserData(fetchedData);
    },
  });

  useEffect(() => {
    if (data) {
      setUserData(data);
    }
  }, [data]);

  useEffect(() => {
    console.log("UserData Updated:", userData);
  }, [userData]);

  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1023px)");
  const isLaptopOrDesktop = useMediaQuery("(min-width: 1024px)");

  const closeOptions = () => {
    setIsProfileClicked(false);
  };

  const getUser = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(`${window.env_url}/v1/users`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setNavBarData(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };


  console.log("coachData?.id", coachData?.id)

  const userId = sessionStorage.getItem("userId");
  const _userId = Number(userId);

  const fetchSingleCoach = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/v1/coaches/byCoachuserId/${_userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("single response", response.data.body[0]);
      setCoachData(response?.data?.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };
  console.log("single response2", coachData);

  useEffect(() => {
    getUser();
    fetchSingleCoach();
  }, []);

  let imgPath;
  if (profImage?.includes("avataaars.io")) {
    imgPath = profImage;
  } else {
    imgPath = getFileUrl + navBarData?.image;
  }

  const navigate = useNavigate();
  const adminImage = localStorage.getItem("adminImage");
  console.log("adminImage", adminImage);
  let appLogo;
  if (adminImage === "undefined") {
    appLogo = IncuHubLogo;
  } else {
    appLogo = getFileUrl + localStorage.getItem("adminImage");
  }
  let appName;

  if (localStorage.getItem("appname") === "undefined") {
    appName = "IncuHub";
  } else {
    appName = localStorage.getItem("appname");
  }

  // sessionStorage.setItem("fullName", `${firstname} ${lastname}`);

  return (
    <div className="margin-root">
      <nav className="navbarClass">
        {/* <div className="nav-icon">
          <NavIcon id="navIconSvg" />
        </div> */}
        <div
          className="navLogoContainer"
          onClick={() => navigate("/home")}
          style={{ cursor: "pointer" }}
        >
          {/* <img className="nav-logo-image" src={appLogo} alt="" /> */}
          <img className="nav-logo-image" src={NFIHLogo} alt="" />
        </div>
        <div className="navbar_content">
          <div className="nav-incubator">
            {/* <span>{appName}</span> */}
            <span>IncuHub</span>
          </div>
          <div className="nav-notif-icon">
            {usertype === "coach" ? (
              <CoachNotificationWidget coachId={coachData?.id} />
            ) : (
              <NotificationWidget />
            )}
          </div>
          <div className="user-details">
            {isLaptopOrDesktop && (
              <p className="nav-username">
                {usertype === "coach"
                  ? userData?.username
                  : `${userData?.firstname ?? ""} ${userData?.lastname ?? ""}`}
              </p>
            )}{" "}
            <div className="user-det-img" style={{ cursor: "pointer" }}>
              <img src={imgPath} className="nav-user-dp" />
              <Dropdown onClick={handleProfile} />
            </div>
          </div>
          {isProfileClicked && <ProfileOptions refetch={refetch} closeProfile={closeOptions} coachId={coachData?.id}/>}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
